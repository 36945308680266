<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>Edit {{this.singular}}</h1>
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="purchaseOrder.createdAt">Created By {{lookupUsername(purchaseOrder.createdBy)}} at {{utils.formatDate(purchaseOrder.createdAt, 'withTime')}}</span>
          <span v-if="purchaseOrder.sealedAt">Sealed By {{lookupUsername(purchaseOrder.sealedBy)}} at {{utils.formatDate(purchaseOrder.sealedAt, 'withTime')}}</span>
          <span v-if="purchaseOrder.voidedAt">Voided By {{lookupUsername(purchaseOrder.voidedBy)}} at {{utils.formatDate(purchaseOrder.voidedAt, 'withTime')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn color="error" @click="openVoidDialog()" :disabled="purchaseOrder.status===-1">Void</v-btn>
            <v-btn class="ml-2" @click="signA()" color="success" :disabled="!canSeal">Sign Purchase Order</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col class="col-3">
          <span>
            <v-autocomplete
                :items="suppliers"
                v-model="purchaseOrder.supplierId"
                :disabled="!canEdit"
                item-text="name"
                item-value="id"
                outlined
                label="Supplier"
                @change="updateSupplierInfo"
            />
          </span>
          <span v-if="canEdit" class="d-flex flex-column">
            <h2>Add Item</h2>
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-column">
                  <v-text-field :loading="searchLoading" clearable label="Search Products" v-model="productSearchTerm" dense outlined @change="searchProduct"></v-text-field>
                  <v-checkbox @change="searchProduct" v-if="getGlobalValue('constrainPurchaseOrdersToSupplierItems')!='true'" style="margin-top: -20px;" label="Constrain search to selected supplier" v-model="constrainSearch"/>
                </span>
                <span>
                  <span v-if="productSearchResults.length>0" class="d-flex flex-row justify-center">
                    <b>Search Results</b>
                    <v-btn x-small color="info" style="margin-left: 10px;" @click="clearSearch">Clear</v-btn>
                  </span>
                  <div v-for="(item, i) in productSearchResults" :key="i">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px; border: 1px solid grey;">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        <v-btn :disabled="purchaseOrder.PurchaseOrderLineItems.findIndex(x => x.productId===item.id)>=0" :loading="item.loading" x-small fab color="success" @click="addProductToPO(item)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span><b>CP: {{item.costPrice}}</b></span>
                        <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                        <span><b>Supplied By: </b><span>{{item.ProductSupplierJoins.map(x => `${suppliers.find(y => y.id===x.supplierId)?.name||"Unknown"} (${utils.formatCurrency(x.costPrice || 0)})`).join(", ")}}</span></span>
                      </span>
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
        <v-col style="background-color: rgba(0,0,0,0.05); border-radius: 10px; border: 1px solid grey; padding: 20px;">
          <div style="height: 100%;">
            <div style="width: 100%;">
              <!-- header -->
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column" style="width: 50%; margin-bottom: 10px;">
                  <!-- <img src="" alt=""> -->
                  <h1 class="d-flex flex-row align-center">
                    <div v-if="updateStatus==2" style="margin-right: 10px; background-color: red; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="updateStatus==0" style="margin-right: 10px; background-color: green; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="updateStatus==1" style="margin-right: 10px; background-color: orange; border-radius: 15px; width: 15px; height: 15px;"></div>
                    Purchase Order<span style="margin-left: 10px;">#{{purchaseOrder.id}}</span>
                  </h1> 
                </div>
              </div>
              <div style="width: 100%; margin-top: 10px;" class="d-flex flex-row justify-space-between">
                <div v-if="purchaseOrder.supplierId" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Supplier</b>
                    <v-btn v-if="!supplierEditing" style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(true)">Edit</v-btn>
                    <v-btn v-if="supplierEditing" style="margin-left: 5px;" color="success" x-small @click="saveSupplierInfo()">Save</v-btn>
                    <v-btn v-if="supplierEditing " style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(false)">Cancel</v-btn>
                  </span>
                  <div v-if="supplierEditing">
                    <v-text-field class="mt-5" label="Company Name" style="width: 90%;" dense v-model="tempSupplierInfo.name"></v-text-field>
                    <v-text-field label="Person of Contact" style="width: 90%;" dense v-model="tempSupplierInfo.contactName"></v-text-field>
                    <v-text-field label="Address" style="width: 90%;" dense v-model="tempSupplierInfo.address"></v-text-field>
                    <v-text-field label="Phone" style="width: 90%;" dense v-model="tempSupplierInfo.phone"></v-text-field>
                    <v-text-field label="Email" style="width: 90%;" dense v-model="tempSupplierInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span v-if="purchaseOrder.supplierInfo.name">{{purchaseOrder.supplierInfo.name}}</span>
                    <span v-if="purchaseOrder.supplierInfo.contactName">{{purchaseOrder.supplierInfo.contactName}}</span>
                    <span v-if="purchaseOrder.supplierInfo.address">{{purchaseOrder.supplierInfo.address}}</span>
                    <span v-if="purchaseOrder.supplierInfo.phone">{{purchaseOrder.supplierInfo.phone}}</span>
                    <span v-if="purchaseOrder.supplierInfo.email">{{purchaseOrder.supplierInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Supplier</b></span>
                  <span>-</span>
                </div>
                <div v-if="purchaseOrder.shipToInfo" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Ship To</b>
                    <v-btn v-if="!shipToEditing" style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(true)">Edit</v-btn>
                    <v-btn v-if="shipToEditing" style="margin-left: 5px;" color="success" x-small @click="saveShipToInfo()">Save</v-btn>
                    <v-btn v-if="shipToEditing " style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(false)">Cancel</v-btn>
                  </span>
                  <div v-if="shipToEditing">
                    <v-text-field class="mt-5" label="Company Name" style="width: 90%;" dense v-model="tempShipToInfo.name"></v-text-field>
                    <v-text-field label="Address" style="width: 90%;" dense v-model="tempShipToInfo.address"></v-text-field>
                    <v-text-field label="Phone" style="width: 90%;" dense v-model="tempShipToInfo.phone"></v-text-field>
                    <v-text-field label="Email" style="width: 90%;" dense v-model="tempShipToInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span>{{purchaseOrder.shipToInfo.name}}</span>
                    <span>{{purchaseOrder.shipToInfo.address}}</span>
                    <span>{{purchaseOrder.shipToInfo.phone}}</span>
                    <span>{{purchaseOrder.shipToInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Ship To</b></span>
                  <span>-</span>
                </div>
              </div>
            </div>
            <div style="border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px;">
              <!-- body -->
                <v-simple-table style="height: 100%; background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width:100px;" class="text-left">
                        QTY
                      </th>
                      <th class="text-center">
                        ID
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-center">
                        SKU
                      </th>
                      <th class="text-left">
                        Unit Price
                      </th>
                      <th class="text-right">
                        Total
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in purchaseOrder.PurchaseOrderLineItems" :style="i===purchaseOrder.PurchaseOrderLineItems.length-1?'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;':''" :key="i">
                      <td>
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            :disabled="!canEdit"
                            type="number"
                            :rules="quantityRules"
                            v-model="item.quantity"
                            min="1"
                            @change="updateLineItem(item)">
                        </v-text-field>
                      </td>
                      <td class="text-center">
                        <router-link :to="'/products/view/'+item.productId">{{item.productId}}</router-link>
                      </td>
                      <td>
                        <span class="pt-5">
                          <span>{{item.productName}}</span>
                          <v-text-field :disabled="!canEdit" @change="updateLineItem(item)" label="Supplier Identifiable Name" outlined class="mt-1" dense v-model="item.metadata.customName"/>
                        </span>
                      </td>
                      <td class="text-center">{{item.sku}}</td>
                      <td>
                        <v-text-field
                          outlined
                          class="mt-5" 
                          style="width:100px;" 
                          dense
                          :disabled="!canEdit"
                          type="number"
                          v-model="item.unitPrice"
                          min="0"
                          @change="updateLineItem(item)">
                        </v-text-field>
                      </td>
                      <td class="text-right"><span v-if="(item.metadata.lineTotal!==null||item.metadata.lineTotal!==undefined)&&item.metadata.lineTotal>-1">{{utils.formatCurrency(item.metadata.lineTotal)}}</span><span v-else>-</span></td>
                      <td style="border-bottom-right-radius: inherit;"><v-btn v-if="canEdit" x-small fab color="error" @click="removeLineItem(item.id)"><v-icon >mdi-close</v-icon></v-btn></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
              <div class="d-flex flex-column align-end">
                <b>Subtotal</b>
<!--                <b>-->
<!--                  <span>-->
<!--                    <v-btn style="margin-left: 5px;" color="warning" x-small @click="()=>{this.$router.push({path: '/taxes/view/'})}">Edit</v-btn>-->
<!--                  </span> -->
<!--                  Tax ({{this.taxRate}}%)</b>-->
                <b>Total</b>
              </div>
              <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                <span class="d-flex flex-column align-start">
                  <div>{{utils.formatCurrency(purchaseOrder.subtotal)}}</div>
<!--                  <div>{{utils.formatCurrency(purchaseOrder.subtotal*(taxRate/100)||0)}}</div>-->
                  <div>{{utils.formatCurrency(purchaseOrder.grandTotal)}}</div>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="voidDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Void this Purchase Order Draft?
        </v-card-title>
        <v-card-text>You will not be able to edit it once voided.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="voidDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="confirmVoid()"
          >
            Void
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="signDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you wish to sign this Purchase order?
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="signDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :disabled="!userPassword"
            @click="signB()"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import utils from "../../plugins/helpers";
  import {mapGetters} from 'vuex'
  export default {
    data () {
      return {
        utils: utils,
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        updateStatus: 0,
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],

        data: {},

        singular: "Purchase Order",
        singularLower: "purchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",

        suppliers: [],
        purchaseOrder: {
          PurchaseOrderLineItems: [],
          subtotal: 0, 
          grandTotal: 0, 
          supplierInfo: {}, 
          shipToInfo: {
            name: "",
            address: "",
            phone: '',
            email: '',
        }},

        taxRate: 10,
        isNewPO: "",

        supplierEditing: false,
        shipToEditing: false,

        tempSupplierInfo: {},
        tempShipToInfo: {},
        renderKey: 0,
        voidDialog: false,
        signDialog: false,
        userPassword: null,
        updateStateValue: 0,

        productSearchResults: [],
        productSearchTerm: "",
        searchLoading: false,
        constrainSearch: false
      }
    },
    async mounted(){
      try{
        this.loader = true;
        if(this.$route.params.id){
          let po = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(po.data.error) throw po.data.error
          po.data.data.PurchaseOrderLineItems.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
          this.purchaseOrder = po.data.data
          console.log(this.purchaseOrder)
          if(this.purchaseOrder.status!==0) await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
          this.purchaseOrder.PurchaseOrderLineItems.forEach(x=>{
            x.metadata.lineTotal = parseInt(x.quantity)*parseFloat(x.unitPrice)
          });
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data
        }

        this.constrainSearch = this.getGlobalValue("constrainPurchaseOrdersToSupplierItems")==="true";

        await this.updatePurchaseOrder();
        await this.updateTotals();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      selectedSupplier(){
        if(this.suppliers.length>0){
          return this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        }
        return "-"
      },
      currentFormattedDate(){
        return moment().format("DD/MM/YYYY")
      },
      canSeal(){
        console.log(this.purchaseOrder)
        if(this.purchaseOrder.status!==0) return false;
        if(!this.purchaseOrder.supplierId) return false;
        if(!this.purchaseOrder.PurchaseOrderLineItems || this.purchaseOrder.PurchaseOrderLineItems.length===0) return false;
        for(let item of this.purchaseOrder.PurchaseOrderLineItems){
          if(item.quantity<=0 || isNaN(item.quantity)) return false;
        }
        return true
      },
      canEdit(){
        if(this.purchaseOrder.status===0) return true;
        return false;
      },
      ...mapGetters(['getWorkingId', 'getEndpoint', 'getGlobalValue', 'lookupUsername'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      signA(){
        if(this.supplierEditing){
          this.snackText = "You are currently editing the supplier info. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        if(this.shipToEditing){
          this.snackText = "You are currently editing the Ship To information. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        for(let item of this.purchaseOrder.PurchaseOrderLineItems){
          if(item.quantity<=0){
            this.snackText = "Your quantity is invalid for "+item.name;
            this.snackBar = true;
            return;
          }
        }
        if(!this.supplierEditing && ! this.shipToEditing) 
          this.signDialog = true;
      },
      async signB(){
        try {
          this.loader = true
          if(this.purchaseOrder.status > 0) throw "Cannot Re-Sign Purchase Order"
          if(!this.userPassword) throw "Password Needed to verify the signature."
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/sign/${this.purchaseOrder.id}`, {password: this.userPassword})
          if(res.data.error) throw res.data.error
          this.snack("Signed!")
          await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }finally{
          this.loader = false
        }
      },
      async addProductToPO(item){
        try {
          if((this.purchaseOrder.PurchaseOrderLineItems.filter(x=>x.productId==item.id)).length>0) throw "Item Already in Purchase Order."
          this.updateStateValue = 1

          item.loading = true;

          let found = item.ProductSupplierJoins.find(x => x.supplierId===this.purchaseOrder.supplierId);
          let cp = found?parseFloat(found.costPrice):0;

          let newItem = {
            poId: this.$route.params.id,
            productId: item.id,
            productName: item.name,
            sku: item.sku,
            unitPrice: cp,
            quantity: 1,
            metadata: {
              lineTotal: item.lineTotal,
              customName: found?(found.metadata?.name?found.metadata.name:item.name):item.name
            },
            loading: false
          }
          let res = await axios.post(`${this.getEndpoint}/api/purchaseorders/lineItem/${this.purchaseOrder.id}`, newItem);
          if(res.data.error) throw res.data.error
          if(!this.purchaseOrder.PurchaseOrderLineItems) this.purchaseOrder.PurchaseOrderLineItems = []
          if(!res.data.data.metadata.lineTotal) res.data.data.metadata.lineTotal = 0
          res.data.data.metadata.lineTotal = parseInt(res.data.data.quantity)*parseFloat(res.data.data.unitPrice)
          this.purchaseOrder.PurchaseOrderLineItems.push(res.data.data)
          item.loading = false;
          await this.updateTotals()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
          this.updateStateValue = 2
        } finally {
          item.loading = false;
          this.updateStateValue = 0
        }
      },
      async removeLineItem(e){
        try {
          this.updateStateValue = 1
          let res = await axios.delete(`${this.getEndpoint}/api/purchaseorders/lineItem/${e}`)
          if(res.data.error) throw res.data.error
          let i = (this.purchaseOrder.PurchaseOrderLineItems.findIndex(x=>x.id===e))
          if(i == -1) throw "Item Could Not Be Found. Refresh this page."
          this.purchaseOrder.PurchaseOrderLineItems.splice(i,1)
          if(!this.purchaseOrder.PurchaseOrderLineItems) this.purchaseOrder.PurchaseOrderLineItems = []
        } catch (error) {
          console.log(error)
          this.snack(error)
          this.updateStateValue = 2
        } finally {
          this.updateStateValue = 0
        }
      },
      async updateLineItem(p){
        try {
          if(!p.quantity) throw "Quantity Cannot be Blank."
          if(!p.unitPrice) throw "Unit Price Cannot be Blank."
          this.updateStateValue = 1
          let res = await axios.put(`${this.getEndpoint}/api/purchaseorders/lineItem/${p.id}`, p)
          if(res.data.error) throw res.data.error
          if(!res.data.data.metadata.lineTotal) res.data.data.metadata.lineTotal = 0
          res.data.data.metadata.lineTotal = parseInt(res.data.data.quantity)*parseFloat(res.data.data.unitPrice)
          let ix = this.purchaseOrder.PurchaseOrderLineItems.findIndex(x=>x.id==p.id)
          ix==-1?null:this.purchaseOrder.PurchaseOrderLineItems.splice(ix, 1, res.data.data)
          await this.updateTotals()
        } catch (error) {
          console.log(error)
          this.snack(error)
          this.updateStateValue = 2
        } finally {
          this.updateStateValue = 0
        }
      },
      async updateTotals(){
        if(!this.purchaseOrder.PurchaseOrderLineItems || this.purchaseOrder.PurchaseOrderLineItems.length===0) return
        this.purchaseOrder.PurchaseOrderLineItems.forEach(item=>{
          if(!item.metadata) item.metadata = {lineTotal: 0}
          if(!item.metadata.lineTotal) item.metadata.lineTotal = 0
        })
        this.purchaseOrder.subtotal = this.purchaseOrder.PurchaseOrderLineItems.reduce((a,x)=>a+parseFloat(x.metadata.lineTotal),0)
        // this.purchaseOrder.grandTotal = ((this.taxRate/100)*this.purchaseOrder.subtotal) + this.purchaseOrder.subtotal
        this.purchaseOrder.grandTotal = this.purchaseOrder.subtotal
        this.$forceUpdate()
      },
      setSupplierEditing(bool){
        if(bool){
          this.tempSupplierInfo = {...this.purchaseOrder.supplierInfo}
        }else{
          this.tempSupplierInfo = {}
        }
        this.supplierEditing = bool
      },
      async saveSupplierInfo(){
        try {
          this.purchaseOrder.supplierInfo = {...this.tempSupplierInfo}
            let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
            if(res.status==200){
              this.setSupplierEditing(false)
          }
          await this.updatePurchaseOrder();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      setShipToEditing(bool){
        if(bool){
          this.tempShipToInfo = {...this.purchaseOrder.shipToInfo}
        }else{
          this.tempShipToInfo = {}
        }
        this.shipToEditing = bool
      },
      async saveShipToInfo(){
        try {
          this.purchaseOrder.shipToInfo = {...this.tempShipToInfo}
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
          if(res.data.error) throw res.data.error
          this.setShipToEditing(false)
          await this.updatePurchaseOrder();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async updatePurchaseOrder(){
        try {
          this.updateStatus = 1
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
          if(res.data.error) throw res.data.error
          // res.data.data.PurchaseOrderLineItems.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
          // this.purchaseOrder = res.data.data
          // this.purchaseOrder.subtotal = this.subtotal
          this.updateStatus = 0
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
          this.updateStatus = 2
        }
      },
      openVoidDialog(){
        this.voidDialog = true
      },
      async confirmVoid(){
        try {
          this.voidDialog = false
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.purchaseOrder.id}`)
          if(res.data.error) throw res.data.error
          await this.$router.push({path: "/purchasing"})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.searchLoading = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimitForPO?val=${encodeURIComponent(this.productSearchTerm)}&constrain=${encodeURIComponent(this.constrainSearch)}&supplierId=${encodeURIComponent(this.purchaseOrder.supplierId)}`);
            if(res.data.error) throw res.data.error

            let products = res.data.data;

            if(products.length===0) throw "No products matching the search criteria."

            if(this.purchaseOrder.supplierId){
              for(let prod of products){
                let found = prod.ProductSupplierJoins.find(x => x.supplierId===this.purchaseOrder.supplierId);
                prod.costPrice = found?utils.formatCurrency(found.costPrice):"No Supplier Data";
                prod.loading = false;
              }
            }

            this.productSearchResults = products
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.searchLoading = false;
        }
      },
      clearSearch(){
        this.productSearchResults = [];
      },
      async updateSupplierInfo(){
        this.productSearchResults = [];
        this.productSearchTerm = "";

        if(!this.purchaseOrder.supplierId){
          this.purchaseOrder.supplierInfo = {
            name: "",
            contactName: "",
            address: "",
            phone: "",
            email: ""
          }
        }
        else{
          let found = this.suppliers.find(x => x.id===this.purchaseOrder.supplierId);
          this.purchaseOrder.supplierInfo = {
            name: found.name,
            contactName: found.contactName,
            address: found.address,
            phone: found.phone,
            email: found.email
          }
        }
        await this.updatePurchaseOrder();
      }
    }
  }
</script>
